import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { WrapperContainer, WrapperSection } from '../../../wrappers'
import { ButtonReactive } from '../../../buttons'
import { TextField, Grid } from '@material-ui/core'
import FormBackdrop from '../../../forms/FormItems/FormBackdrop'
import FormAgreements from '../../../forms/FormItems/FormAgreements'
import { s, globals, colors } from '../../../style'
import { T } from '../../../typo'
import { sTextInput } from '../../../forms/FormStyles'

const ContactForm = ({ data, theme }) => {
  const {
    title,
    subtitle,
    slug_section,
    form_agreements,
    form_button,
    form_success,
    form_failure,
    form_inputs,
    toEmail,
    language,
  } = data

  const initialFormData = {
    message: '',
    phone: '',
    email: '',
  }

  const initialFormAgreements = form_agreements.map((e) => false)
  const [formData, setFormData] = useState(initialFormData)
  const [formAgreements, setFormAgreements] = useState(initialFormAgreements)
  const [mailState, setMailState] = useState('unfilled')
  const [success, setSuccess] = useState(null)
  const [openBackdrop, setOpenBackdrop] = useState(false)

  const isRequiredFilled = Object.entries(form_inputs)
    .map(([k, v]) => v?.isRequired === true && k)
    .filter((e) => e)
    .map((e) => formData[e].trim().length > 0)
    .reduce((sum, next) => sum && next, true)

  const agreementFilled =
    formAgreements.filter((value) => value).length === formAgreements.length

  const validate = useCallback(() => {
    return isRequiredFilled && agreementFilled
  }, [isRequiredFilled, agreementFilled])

  const onSendForm = async () => {
    if (validate()) {
      setSuccess(null)
      setOpenBackdrop(true)
      setMailState('loading')

      axios({
        method: 'POST',
        url: 'https://cleverserver.appclever.pl/sendmail',
        // url: 'http://localhost:5000/sendmail',

        data: {
          ...formData,
          language,
          toEmail,
          template: 'StarkLogContact',
        },
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // delete me later ?
        },
      })
        .then((res) => {
          // console.log(res)
          if (res.status === 200) {
            setSuccess(true)
            setOpenBackdrop(true)
            setFormData(initialFormData)
            setFormAgreements(initialFormAgreements)
            // console.log(res)
            // console.log('SUCCESS')
            // console.log(res.data.redirectUrl)
            if (res.data.redirectUrl)
              window.location.href = res.data.redirectUrl
          } else {
            setOpenBackdrop(true)
            setSuccess(false)
            // console.log(res)
            // console.log('MAIL FAILURE')
          }
        })
        .catch((error) => {
          setOpenBackdrop(true)
          setSuccess(false)
          // console.log(error)
          // console.log('REQ FAILURE')
        })
    }
  }

  const onValueChange = (event) =>
    setFormData({ ...formData, [event.target.name]: event.target.value })

  useEffect(() => {
    if (validate()) setMailState('filled')
    else setMailState('unfilled')
  }, [validate, setMailState])

  return (
    <WrapperSection id={slug_section} noMarginTop>
      <WrapperContainer theme={theme} extraCss={sContainer} fullMobile>
        <div css={sHeadlineContainer}>
          <T
            d={32}
            m={24}
            bold
            condensed
            extraCss={{
              lineHeight: '1.62em',
              color: colors[theme].main,
              [s.md]: { padding: ' 0 2rem' },
            }}>
            {title}
          </T>
        </div>

        {subtitle && (
          <T
            m={14}
            d={14}
            mb={2}
            o={0.6}
            extraCss={{
              color: colors[theme].main,
              [s.md]: { padding: ' 0 2rem' },
            }}>
            {subtitle}
          </T>
        )}
        <Grid container css={sExpandedContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            css={sExpandedContainer.item}>
            <TextField
              name="message"
              label={form_inputs.message?.label}
              placeholder={form_inputs.message?.placeholder}
              helperText={form_inputs.message?.help}
              value={formData.message}
              variant="filled"
              fullWidth
              multiline
              rows={4}
              onChange={onValueChange}
              css={[sTextInput(theme)]}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            css={[
              sExpandedContainer.item,
              { display: 'flex', flexDirection: 'column' },
            ]}>
            <TextField
              name="email"
              label={`${form_inputs.email?.label} ${
                form_inputs.email.isRequired === true ? '*' : ''
              }`}
              placeholder={form_inputs.email?.placeholder}
              helperText={form_inputs.email?.help}
              value={formData.email}
              variant="filled"
              fullWidth
              onChange={onValueChange}
              css={[sTextInput(theme)]}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            css={[
              sExpandedContainer.item,
              { display: 'flex', flexDirection: 'column' },
            ]}>
            <TextField
              name="phone"
              label={`${form_inputs.phone?.label} ${
                form_inputs.phone.isRequired === true ? '*' : ''
              }`}
              placeholder={form_inputs.phone?.placeholder}
              helperText={form_inputs.phone?.help}
              value={formData.phone}
              variant="filled"
              fullWidth
              onChange={onValueChange}
              css={[sTextInput(theme)]}
            />
          </Grid>
        </Grid>
        <FormAgreements
          theme={theme}
          data={form_agreements}
          formAgreements={formAgreements}
          setFormAgreements={setFormAgreements}
        />
        <ButtonReactive
          onClickFunction={onSendForm}
          active={mailState === 'filled'}
          theme={theme}
          center>
          {mailState === 'unfilled'
            ? form_button.fill
            : mailState === 'filled'
            ? form_button.send
            : form_button.send}
        </ButtonReactive>
      </WrapperContainer>
      <FormBackdrop
        success={success}
        theme={theme}
        open={openBackdrop}
        setOpen={setOpenBackdrop}
        dataSuccess={form_success}
        dataFailure={form_failure}
      />
    </WrapperSection>
  )
}

const sContainer = {
  [s.md]: {
    padding: globals.spacing.inside.desktop / 2,
  },
}

const sHeadlineContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  p: {
    [s.sm_down]: { marginBottom: globals.spacing.inside.mobile / 2 },
  },
  [s.sm_down]: {
    marginBottom: globals.spacing.inside.mobile / 2,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  [s.md]: {
    marginBottom: globals.spacing.inside.desktop / 2,
  },
}

const sExpandedContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  // backgroundColor: 'rgba(0,0,255, 0.1)',

  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
    position: 'relative',
  },
}

export default ContactForm
